<script setup lang="ts">
const currentYear = new Date().getFullYear()
</script>

<template>
  <footer class="bg-milky-200 mt-auto flex justify-center">
    <div class="max-w-[1012px] w-full px-4">
      <div>
        <div>
          <nuxt-link href="/" aria-label="Navigate to main page" class="mt-12 mb-8  flex items-center justify-start">
            <svg class="text-black h-[25px] w-[75px]">
              <use xlink:href="~/assets/i/sprite/sprite.svg#logo" />
            </svg>
          </nuxt-link>
        </div>
        <div class="mt-4 mb-16 sm:mb-24 flex sm:flex-row flex-col gap-y-4 justify-between items-start sm:items-center">
          <div class="text-primary-small flex flex-col">
            <span>© ООО «Ранг Интеллект», 2019-{{ currentYear }}</span>
            <div class="flex gap-0 sm:gap-3 lg:flex-row lg:gap-3 flex-col">
              <NuxtLink to="/privacy">
                <span class="border-b border-secondary-300">
                  Политика конфиденциальности
                </span>
              </NuxtLink>
              <NuxtLink to="/license">
                <span class="border-b border-secondary-300">
                  Лицензия
                </span>
              </NuxtLink>
            </div>
          </div>
          <div>
            <NuxtLink class="text-blue-500 cursor-pointer" to="mailto: art@rang.ai">
              <span class="border-b border-blue-300">art@rang.ai</span>
            </NuxtLink>
          </div>
          <div class="sm:order-last order-first flex flex-nowrap items-center gap-5">
            <NuxtLink target="_blank" to="https://t.me/ideasclinic" class="flex flex-row gap-2 items-center cursor-pointer hover:text-blue-500">
              <Icon name="logos:telegram" class="text-[32px]" />
              <span class="text-primary-small">Телеграм-канал</span>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">

</style>
